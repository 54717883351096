// Import the functions you need from the SDKs you need
// в index.html есть элементы на которых построена авторизация !!!
// import { ConstructionOutlined } from "@mui/icons-material";
import { initializeApp } from "firebase/app";
import { getAuth, RecaptchaVerifier, signInWithEmailAndPassword, signInWithPhoneNumber, signOut } from "firebase/auth";
// import { getFirestore } from "firebase/firestore";
// import { doc, getDoc, setDoc } from "firebase/firestore"; 

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
};

// Initialize Firebase
// инициализация в index.js файле в самом начале, что бы все было ОК
// прописывается объект window.myAuth
const initFB = () => {
  const app = initializeApp(firebaseConfig);
  // const db = getFirestore(app);
  // const auth = getAuth(app);
  window.myAuth = getAuth(app);

  // window.myDB = getFirestore(app);

  // getDoc(doc(window.myDB, 'tasty/favdata234x60')).then((s) =>{
  //   console.log(s.data());
  // });

  // загрузка данных из файлов в БД (только для примера и инициализации) 

  // let favlocaldata234x60 = require('assets/data/favdata234x60.json');
  // let favlocaldata120x90 = require('assets/data/favdata120x90.json');
  // let localdata = require('assets/data/data.json');
  
  // setDoc(doc(window.myDB, "tasty", "favdata234x60"), {
  //   ...favlocaldata234x60
  // });

  // setDoc(doc(window.myDB, "tasty", "favdata120x90"), {
  //   ...favlocaldata120x90
  // });

  // setDoc(doc(window.myDB, "tasty", "menu0"), {
  //   ...localdata
  // });

}

// создание RecaptchaVerifier (первое, что нужно сделать для авторизации через СМС)
// инициализация в index.js файле в самом начале, что бы все было ОК
const makeRecaptchaVerifier = (id) => {
  // id - ссылка на id-elementDom
  // window.recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
  window.recaptchaVerifier = new RecaptchaVerifier(id, {
    'size': 'invisible', //invisible
    'callback': (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        // onSignInSubmit();
        console.log("+callback-captha");
        console.log(response);
    },
    'expired-callback': () => {
        // Response expired. Ask user to solve reCAPTCHA again.
        // ...
        console.log("-expired-callback-captha");
      }
    }, window.myAuth);
}

//
const sendSmsToPhoneNumber = (phoneNumber) => {
    // отпарвка кода на телефон работает 116978 +1 650-555-1233 - 123456
    // const phoneNumber = '+79164050855';
    // const phoneNumber = '+16505551234';
    const appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(window.myAuth, phoneNumber, appVerifier)
        .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        console.log("+");
        console.log(confirmationResult);
        // return ("+");
        // ...
        }).catch((error) => {
        // Error; SMS not sent
        // ...
            console.log("-");
            console.log(error);
            // return ("-");
        });
}

const confirmationCode = (code) => {
// проверка кода и телефона
    // const code = getCodeFromUserInput();
    // сработала в консоли. Видимо рабоать должно не сразу, а только отдельно от выдачи кода (из диалога должно работать хорошо)
    // const code = '116978';
    // window.confirmationResult !!! confirmationResult.verificationId (возможно)
    // 'AOO2nWXaLXKvLXOGwfksHoHqhxBEdjP9EwZCClvNm7C1kTRpAzZ8b7M_lqRlPBZsy01xPwsm59Ek6kwB8dxlLfOvo3_pSu5ChejaO8VoJcfKkpqVWnTx6UpopFUo_yoDzaZKPPaBFMY8yw2kYeNOOD_nj8wCApRm-A'
    // code = 116978
    // var credential = firebase.auth.PhoneAuthProvider.credential(confirmationResult.verificationId, code);
    // firebase.auth().signInWithCredential(credential);
    // window.myAuth.currentUser null или содержится (phoneNumber, uid, displayName, email, signed)

    console.log(">verification code");

    const confirmationResult = window.confirmationResult;
    confirmationResult.confirm(code).then((result) => {
    // User signed in successfully.
    const user = result.user;
    // ...
        console.log("+!");
        console.log(user);
    }).catch((error) => {
    // User couldn't sign in (bad verification code?)
    // ...
        console.log("-");
        console.log(error);
    });
}

const signInWithEmailPassword = (email, password) => {
    signInWithEmailAndPassword(window.myAuth, email, password)
        .then((userCredential) => {
        const user = userCredential.user;
            console.log("+");
            console.log(user);
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log("-");
            console.log(errorCode); // хороший ответ по причине
            console.log(errorMessage); // хороший ответ по причине
        });
    // авторизация с e-mail
}

// выйти из регистрации
const logOut = () => {
  signOut(window.myAuth).then(() => {
    // Sign-out successful.
    console.log("logOut +");  
  }).catch((error) => {
    // An error happened.
    console.log("logOut -");
  });
}

// наблюдение за состоянием авторизации ??? (мониторинг события)
const onAuthStateCh = (callback) => {
  // if (window.myAuth) { 
    window.myAuth.onAuthStateChanged((user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        // информирование о входе пользователя и статусе SIGED IN
        // console.log("user siged in " + user.uid);
        // (phoneNumber, uid, displayName, email, signed)
        callback({
          uid: user.uid,
          displayName: user.displayName,
          phoneNumber: user.phoneNumber,
          email:  user.email,
          signed: 1,
        });
        // ...
        // callback();
      } else {
        // User is signed out
        // ...
        console.log("user siged out");
        callback({
          signed: 0,
        });      
      }
    });
//   } else {
//     console.log("Not obj window.myauth");
//   }
}

// https://firebase.google.com/docs/auth/web/phone-auth?authuser=0&hl=en
// результат авторизации var credential = window.confirmationResult.verificationId
// var credential = firebase.auth.PhoneAuthProvider.credential(confirmationResult.verificationId, code);
// проверка firebase.auth().signInWithCredential(credential); 

export { initFB, signInWithEmailPassword, confirmationCode, sendSmsToPhoneNumber, makeRecaptchaVerifier, logOut, onAuthStateCh };
