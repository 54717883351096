// for animation
// + конфигурация

const getOffsetRect = (elem) => {
    // (1)
    var box = elem.getBoundingClientRect()
    
    // (2)
    var body = document.body
    var docElem = document.documentElement
    
    // (3)
    var scrollTop = window.pageYOffset || docElem.scrollTop || body.scrollTop
    var scrollLeft = window.pageXOffset || docElem.scrollLeft || body.scrollLeft
    
    // (4)
    var clientTop = docElem.clientTop || body.clientTop || 0
    var clientLeft = docElem.clientLeft || body.clientLeft || 0
    
    // (5)
    var top  = box.top +  scrollTop - clientTop
    var left = box.left + scrollLeft - clientLeft

    // (6) дополнительно выводм ширину
    let source_rect = elem.parentNode.getBoundingClientRect();

    let scr_height = window.parent.innerHeight;
    let scr_width = window.parent.innerWidth;

    // отладка расчета
    // console.log(box.top, scrollTop, clientTop, scr_height, scr_width, source_rect);
    // ход по вертикали
    // console.log( Math.round((scr_height - box.top + 10)));
    // ход по горизонтали
    // console.log( Math.round((scr_width - box.left + 10)));
    // ход step_h

    return { top: Math.round(top), 
            left: Math.round(left), 
            width: Math.round(source_rect.width), 
            height: Math.round(source_rect.height),
            step_h: Math.round((scr_height - box.top - source_rect.height / 2)),
            step_w: Math.round((scr_width - box.left - source_rect.width / 2))
          }
  }
  // const startAnimationlocal = (id, rect, s1) => {
  const startAnimationlocal = (id, name_anim) => {
    // класс анимации animation-css-frames описан в index.css
    // упарвление наклоном через из менение значения констант для конкретного элемета !!!!
    // --offset-top --offset-left
    // https://javascript.ru/ui/offset
    // https://learn.javascript.ru/coordinates
    // source-id = id
    // target-id = root-for-animation
    // console.log("js startlocal");
    let source = document.getElementById(id);
    // console.log(id);
    // console.log(source);
    let p_new = source.cloneNode(true);
    // let source_rect = source.parentNode.getBoundingClientRect(); // локальные размеры - габариты
    let source_offset = getOffsetRect(source); // абсолютные координаты - смещение от начала страницы
    // let source_offset = rect; //!!!++++
    // console.log(source_offset); //!!!
    p_new.style.position = 'absolute';
    p_new.style.top = Math.round(source_offset.top)+'px';
    p_new.style.left = Math.round(source_offset.left)+'px';
    p_new.style.width = Math.round(source_offset.width)+'px'; // замена с source_rect на source_offset
    p_new.style.setProperty('--offset-top', source_offset.step_h + 'px');
    p_new.style.setProperty('--offset-left', source_offset.step_w + 'px');
    // p_new.style.zIndex = 0;
    p_new.id = source.id + "_anim"
    // p_new.style.animation = "0.95s cubic-bezier(0.79, -0.04, 0.21, 0.73) 0s 1 normal none running animation-css-frames";
    p_new.style.animation = "0.95s cubic-bezier(0.79, -0.04, 0.21, 0.73) 0s 1 normal none running";
    // p_new.style.animationName = "animation-css-frames";
    p_new.style.animationName = name_anim;
    // console.log(source_rect);
    // console.log(source_offset);
    p_new.onanimationend = () => {
      // console.log("end-animation-from-js1-local");
      target.removeChild(p_new);
    };
    let target = document.getElementById("root-for-animation");
    target.appendChild(p_new);

  }
  // this animation (константа для длительности анимаций в проекте)
const delayForAnimation = 300;

export { startAnimationlocal, getOffsetRect, delayForAnimation };
