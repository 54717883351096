import React from 'react';
import Footer from "./Footer";
import {connect} from "react-redux";
// import {logout} from "../../redux/auth-reducer";

class FooterContainer extends React.Component {

    componentDidMount() {
    //     this.props.getAuthUserData();
        // console.log(this.props);
    }

    render() {
        return <Footer {...this.props} />
    }
}

const mapStateToProps = (state) => ({
    // isAuth: state.auth.isAuth,
    // login: state.auth.login,
    count: state.app.count,
    // lastinsertitem: state.app.lastinsertitem
});

export default connect(mapStateToProps) (FooterContainer);
// export default FooterContainer;
// сделать бэдж для корзины (если есть уже, что - то в стейджинге)
