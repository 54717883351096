import React from 'react';
import Listitems from "./Listitems";
import {connect} from "react-redux";
// import {logout} from "../../redux/auth-reducer";
// import { StyleRoot } from 'radium';
// import {addItemInCart, clearLastInsertItem, updateRectLastInsertItem} from "../../redux/app-reducer";
import { addItemInCart, clearLastInsertItem } from "../../redux/app-reducer";

class ListitemsContainer extends React.Component {

    componentDidMount() {
    //     this.props.getAuthUserData();
    // посмотреть State
        // console.log(this.props);
    }

    render() {
        return (
            // <StyleRoot>
                <Listitems {...this.props} />
            // </StyleRoot>
            )
    }
}

let mapStateToProps = (state) => {
    return {
        lastinsertitem: state.app.lastinsertitem,
        // rect: state.app.rect,
    }
  }
  
let mapDispatchToProps = (dispatch) => {
    return {
        laddCart: (addCartItem) => {
            dispatch(addItemInCart(addCartItem));
        },
        lclear: () => {
            dispatch(clearLastInsertItem());
        },
        // updaterect: (rect) => {
        //     dispatch(updateRectLastInsertItem(rect))
        // }
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (ListitemsContainer);
