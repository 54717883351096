//Row в списке (прайсе) 

import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import s from './Listitems.module.css';
import Typography from '@mui/material/Typography';
// import Proxy from "./Proxy";
import Badge from '@mui/material/Badge';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';

import { useNavigate } from "react-router-dom";
// import { getOffsetRect } from "../common/utils";
// import { Navigate } from "react-router-dom";
// import { Link } from "react-router-dom";
// import Radium from 'radium';

// props.lastinsertitem - последний добавленный в ChartID
// props.clearLastInsertItem - функция для обнуления значения в глобальном state

const ListItemsRow = (props) => {

    // const theme = useTheme();
    // console.log(theme);

    let navigate = useNavigate();
  
    let price = 0;
    if (Object.keys(props.item).includes('choice')) {
      price = props.item.choice[0].price;
    } else {
      price = props.item.price;
    }
  
    price = price.split(".")[0];
  
    const handleclick = (item) => {
        // быстрая продажа
      // console.log(item);
      let extItem = (Object.keys(item).includes('choice') || Object.keys(item).includes('extensions')) ? true : false;
      // подготовить координаты для анамации и сохранить в Store
      // let rect = getOffsetRect(document.getElementById("cardmedia-" + item.id));
      // props.updaterect(rect);
      if (extItem) {
        navigate("/item/"+item.id);
      } else {
        // быстрая продажа
        // console.log(event.target.getAttribute("data-id") + " => " + event.target.innerText);
        let count = (Object.keys(item).includes('extensions')) ? item.extensions.length : 0;
        const chosenEx = Array(count).fill(false);
        // const cost = price;
        // const product = {"itemData": item, "chosen":0, "chosenEx":chosenEx, "cost":Number(price), "remaind":1, "rect":rect}
        const product = {"itemData": item, "chosen":0, "chosenEx":chosenEx, "cost":Number(price), "remaind":1}
        // добавить item в Cart
        props.laddCart(product);
        // console.log(product);
      }
    }
  
    const handleclickItem = (id) => {
      // запуск формы по конкретному товару
      // props.navigate("/item/"+id);
      // let rect = getOffsetRect(document.getElementById("cardmedia-" + id));
      // props.updaterect(rect);
      navigate("/item/"+id);
      // return(<Navigate to={"/item/"+id} replace={true} />);
      // console.log(id);
    }
  
    const superItem = (Object.keys(props.item).includes('super') ? true : false);
  
    return (

      <Card sx={{ display: 'flex'}}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }} className = {s.containerItemIMG} onClick={() => handleclickItem(props.item.id)}>
          <Badge badgeContent={"хит"} invisible={!superItem} color="primary">
            <CardMedia
              id = {"cardmedia-" + props.item.id}
              component="img"
              // sx={{ width: 75}}
              image={"goods/" + props.item.img}
              alt="Live from space"
              loading="lazy"
            />
            </Badge>
        </Box>
      
        <Box sx={{ display: 'flex', flexDirection: 'column' }} className = {s.containerItemTEXT}>
          <CardContent sx={{ flex: '1 0 auto' , padding: 0}}>
            {/* title */}
            <Typography component="div" variant="h8" onClick={() => handleclickItem(props.item.id)}>
              {props.item.title}
            </Typography>
            {/* sx={{fontSize : "14px"}} */}
            {/* composition and description*/}
            <Typography variant="subtitle1" color="text.secondary" component="div" sx={{fontSize : "14px"}} onClick={() => handleclickItem(props.item.id)}>
              { (Object.keys(props.item).includes('composition') ? props.item.composition : props.item.description) } 
            </Typography>
            {/*  кнопка купить */}
            <Typography sx={{textAlign: "right"}}>
              <Button variant="outlined" size="small" 
                onClick={() => {
                  handleclick(props.item);
                  // анимация компонента в React
                  // startAnimation("cardmedia-" + props.item.id);!!!
                  }
                } 
                data-id={props.item.id}>{price + " ₽"}
              </Button>
            </Typography>
          </CardContent>
        </Box>
  
      </Card>
    );
}
  

// export default Radium(ListItemsRow);
export default ListItemsRow;
