import React from 'react';
import s from './ItemExtention.module.css';
import Box from '@mui/material/Box';

// конкретный экземпляр extention (карточка)
const ItemExtention = (props) => {
  
    const { sx, ...other } = props;
    // console.log(props);

    // const [ chosenEx, setСhosenEx ] = React.useState( props.chosenEx );

    // const handleChange = () => {
      // setСhosenEx( !chosenEx );
    // };

    const myclassName = ( props.chosenEx ? (s.ChoiceItem + " " + s.Selected) : s.ChoiceItem )

    return (
      <Box
        sx={{
          bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
          color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
          border: '1px solid',
          borderColor: (theme) =>
            theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
          p: 1,
          borderRadius: 2,
          fontSize: '0.875rem',
          fontWeight: '700',
          ...sx,
        }}
        {...other}
        className = { myclassName }
        onClick = {props.handleChange}
      >
  
        <img src={ '/extensions/'+props.img } alt = '' />
  
        <div>
          <div> { props.ingredients }</div>
          <div> { props.price } р.</div>
        </div>
    
      </Box>   
    );  
  }

export default ItemExtention;

