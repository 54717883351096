// import {getAuthUserData} from "./auth-reducer";
import {getDataSite} from "../api/api";

const INITIALIZED_SUCCESS = 'INITIALIZED_SUCCESS';
const ADD_ITEM_IN_CART = 'ADD_ITEM_IN_CART';
const UPDATE_ITEM_IN_CART = 'UPDATE_ITEM_IN_CART';
const CLEAR_CART = 'CLEAR_CART';
const INC_ITEM_IN_CART = 'INC_ITEM_IN_CART';
const DEC_ITEM_IN_CART = 'DEC_ITEM_IN_CART';
const CLEAR_LAST_INSERT_ITEM = 'CLEAR_LAST_INSERT_ITEM';
// const UPDATE_RECT_LAST_INSERT_ITEM = 'UPDATE_RECT_LAST_INSERT_ITEM';
const UPDATE_LAST_SEARCH = 'UPDATE_LAST_SEARCH';

const initialState = () => {
    // всегда принудительно при старте сбрасывать initialized
    if (localStorage['stor-app']) {
        let s = JSON.parse(localStorage['stor-app']);
        s.initialized = false;
        return ( s )
    } else {
        return (
            {
                cart:[],
                count:0,
                bill:0,
                initialized: false,
                lastinsertitem:0,
                lastsearch:[],
            }
        )
    }
}

// пересчет количества и итоговой суммы в state 
const recalc = (state) => {

    let count = state.cart.length;
    let bill = state.cart.reduce((s, item)=>{
        return s + item.cost;
    }, 0);

    // console.log(bill);
    state.count = count;
    state.bill = bill;
    // return (state)
}

// reducer
const appReducer = (state = initialState(), action) => {
    let stItem;
    let coItem;
    switch (action.type) {
        case INITIALIZED_SUCCESS:
            return {
                ...state,
                initialized: true
            }
        case ADD_ITEM_IN_CART:
            // console.log(action.value);
            // state.cart.push(action.value);
            let st = [];
            Object.assign(st, state.cart); 
            st.push(action.value);
            // console.log(state.cart);
            return {
                ...state,
                cart: st,
                count: state.count + action.value.remaind,
                bill : state.bill + action.value.cost,
                lastinsertitem: action.value.itemData.id,
                // rect : action.value.rect
            }
        // case UPDATE_RECT_LAST_INSERT_ITEM:
        //     return {
        //         ...state,
        //         rect: action.value
        //     }
        case UPDATE_ITEM_IN_CART:
            // обновление в корзине
            // console.log(action);
            // console.log(state);     
            let {chosen, chosenEx, cost, itemData, remaind} = action.value;
            state.cart[action.value.lid] = {chosen, chosenEx, cost, itemData, remaind};
            recalc(state);
            return {
                ...state
            }
        case CLEAR_CART:
            return {
                ...initialState(),
                initialized: true
            }
        case CLEAR_LAST_INSERT_ITEM:
            return {
                ...state,
                lastinsertitem:0
            }
        case INC_ITEM_IN_CART:
            // console.log(action.value);
            // console.log(state);
            stItem = state.cart[action.value];
            coItem = stItem.cost / stItem.remaind;
            stItem.remaind += 1;
            stItem.cost = coItem *  stItem.remaind;
            recalc(state);
            return {
                ...state
            }
        case DEC_ITEM_IN_CART:
            stItem = state.cart[action.value];
            coItem = stItem.cost / stItem.remaind;
            stItem.remaind -= 1;
            stItem.cost = coItem *  stItem.remaind;
            if (stItem.remaind === 0) {
                state.cart.splice(action.value, 1);
            }
            recalc(state);
            return {
                ...state
                // cart: st1
            }
        case UPDATE_LAST_SEARCH:
            return {
                ...state,
                lastsearch: action.value
            }
        default:
            return state;
    }
}

// actions
const initializedSuccess = ()       => ({type: INITIALIZED_SUCCESS });
const addCartState = (item)         => ({type: ADD_ITEM_IN_CART, value: item});
const updateCartState = (item)      => ({type: UPDATE_ITEM_IN_CART, value: item});
const clearCartState = ()           => ({type: CLEAR_CART});
const incCartState = (item)         => ({type: INC_ITEM_IN_CART, value: item});
const decCartState = (item)         => ({type: DEC_ITEM_IN_CART, value: item});
const clearLastInsertItemState = () => ({type: CLEAR_LAST_INSERT_ITEM});
// const updateRectLastInsertItemState = (rect) => ({type: UPDATE_RECT_LAST_INSERT_ITEM, value: rect});
const updateLastSearchState = (item) => ({type: UPDATE_LAST_SEARCH, value: item});

export const initializeApp = () => (dispatch) => {
    // let promise = dispatch(getAuthUserData());
    // Promise.all([promise])
    //     .then(() => {
    //     dispatch(initializedSuccess());
    // })
    Promise.all(getDataSite('/'))
      .then(function (results) {
        window.localdata = results[0].data;
        window.favlocaldata234x60 = results[1].data;
        window.favlocaldata120x90 = results[2].data;
        // console.log(results[0].data);
        dispatch(initializedSuccess());
    });

}

export const addItemInCart = (item) => (dispatch) => {
    dispatch(addCartState(item));
}

export const updateItemInCart = (id) => (dispatch) => {
    dispatch(updateCartState(id));
}

export const clearCart = () => (dispatch) => {
    dispatch(clearCartState());
}

export const incItemInCart = (id) => (dispatch) => {
    dispatch(incCartState(id));
}

export const decItemInCart = (id) => (dispatch) => {
    dispatch(decCartState(id));
}

export const clearLastInsertItem = () => (dispatch) => {
    dispatch(clearLastInsertItemState());
}

// export const updateRectLastInsertItem = (rect) => (dispatch) => {
//     dispatch(updateRectLastInsertItemState(rect));
// }

export const updateLastSearch = (item) => (dispatch) => {
    dispatch(updateLastSearchState(item));
}

export default appReducer;
