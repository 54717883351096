import React from 'react';
import s from './Pagecart.module.css';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
// import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grow from '@mui/material/Grow';
// import ListItem from '@mui/material/ListItem';
// import Button from '@mui/material/Button';

import { IconButton } from '@mui/material';
import Add from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import { useNavigate } from "react-router-dom";

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';

import { FormControl, InputLabel, Select, MenuItem, Badge} from '@mui/material';

import { delayForAnimation } from '../../components/common/utils';

const Pagecart = (props) => {

    // console.log(props);

    const {magOption, maxTable} = props.stateCfg;

    const navigate = useNavigate();
    // вернуться на страницу (назад на одну)
    const backMain = () => {
        // navigate(-1);
        navigate("/list");
    }

    // список 
    // 1/4 картинка 3/4 Тайтл + ниже описание + ингрибинеты (выбора)
    // стркоа в 1/3 от (строки писка) стоимость по позиции (слева), чнопки увеличения и уменьшения количества | общая длинна на пополам

    const RowItemCart = (props) => {
        // const {item} = props;
        // const {chosen, chosenEx, itemData} = props.item;
        const {chosenEx, itemData} = props.item;
        
        let a = [];
        chosenEx.map((item,index) => {
            if (item) {
                a.push(itemData.extensions[index].ingredients)
            }
            return (null)
        });

        const handleRowonClick = () =>{
            navigate("/tuning/"+props.index);
        }
      // определение бэйджа SUPER
      let superItem = Object.keys(itemData).includes('super') ? true : false;
        return(
            <Card sx={{ display: 'block' , marginBottom:'10px', paddingBottom:'8px', paddingTop:'8px'}} >
                {/*  редактирвоание позиции в корзине (пробуем использовать общий диалог для этого) */}
                <Box sx={{ display: 'flex', borderBottom: '#b3adaa', borderBottomStyle: 'outset', marginBottom: '8px'}} onClick={ () => (handleRowonClick()) } >
                    <Box sx={{ display: 'flex', flexDirection: 'column' }} className = {s.containerItemIMG} >
                        <Badge badgeContent={"хит"} invisible={!superItem} color="primary">
                            <CardMedia
                                component="img"
                                sx={{ width: 75}}
                                image={"goods/" + itemData.img}
                                alt="Live from space album cover"
                            />
                        </Badge>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }} className = {s.containerItemTEXT}>
                    {/* <CardContent sx={{ flex: '1 0 auto' , padding: 0}}> */}
                        <Typography sx={{textAlign: "left"}}>{itemData.title}</Typography>
                        <Typography sx={{textAlign: "left", fontSize: "x-small"}} variant="subtitle1" color="text.secondary">{itemData.composition}</Typography>
                        {/* тут добавить список Extentions, что выбрано FDN */}
                        {/* chosen */}
                        {/* chosenEx */}
                        {/* itemData */}
                        {(a.length > 0)?
                        <Typography sx={{textAlign: "left", fontSize: "x-small", marginTop:"5px"}} variant="subtitle1" color="text.secondary">
                                {"Доп: " + a.join (", ")}
                        </Typography>
                        :null}
                    {/* </CardContent> */}
                    </Box>
                </Box>
                {/* <hr/> */}
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }} className = {s.containerItemTEXT}>
                    <Box>
                        <span> {props.item.cost} P</span>
                    </Box>
                    <Box className = {s.IncDecButton}>
                    {/* <IconButton aria-label="delete" onClick={() => {
                            props.itemInc(props.index);
                        }}>
                            <Add />
                        </IconButton>
                        <span> {props.item.remaind} </span> */}
                        <IconButton aria-label="delete" onClick={() => {
                            props.itemDec(props.index);
                        }}>
                            <RemoveIcon />
                        </IconButton>
                        <span> {props.item.remaind} </span>
                        <IconButton aria-label="delete" onClick={() => {
                            props.itemInc(props.index);
                        }}>
                            <Add />
                        </IconButton>
                    </Box>
                </Box>
            </Card>        
        );
    }

    const makeEnding = (i) => {
        switch (i) {
            // case 0:
            // case 5:
            // case 6:
            // case 7:
            // case 8:
            // case 9:
            // case 10:
            // case 11:
            //     return("ов");
                // break;
            case 1:
                return("");
            case 2:
            case 3:
            case 4:
                return("a");
            default:
                return("ов");
        }
    } 


    // window.screen.height
    const [height, setHeight] = React.useState(window.screen.height);
    // const [show, setShow] = React.useState(true);

    const CalcMarg = () => {
        let o = document.getElementById('paper-footer');
        var m = 0;
        try {
            m = o.clientHeight;
        } catch (e) {
            // ошибка, если неуспевает отрендериться страница !!!
            // console.log(e);
            return(m);
        } 
        return(m);
    }

    React.useEffect(() => {
        setHeight(CalcMarg);
      },[height]);

    // console.log(height);
    // console.log(magOption);
    
    const {itemInc, itemDec} = props;

    const [table, setTable] = React.useState('1');
    
    const handleChangeTable = (event) => {
        setTable(event.target.value);
    };

    const sendCart = (item) => {
        // отправка корзины, если все ОК, то нужно обнулить состояние корзины !!!
        // magOption реакция в зависимости от выбора el-menu vface stuf
        let a = {
            "cart":props.stateCart.cart, 
            "bill":props.stateCart.bill, 
            "count": props.stateCart.count, 
            "table":table, 
            "magOption":props.stateCfg.magOption
        };
        console.log(a, item);
    }

    return (
         
        <div className = {s.containerItem} sx={{ backgroundColor: 'background.default' }}>
            {/* тут заголовок */}
            <Paper elevation={1} className = {s.Titlepagecart}>
                <h3>Корзина</h3>
            </Paper>
            {/* второй заголовок с количеством и суммой счета */}
            <div className = {s.TitlepagecartSecond}>
                { props.stateCart.count > 0 ?
                <span><h3>{props.stateCart.count} товар{makeEnding(props.stateCart.count)} на {props.stateCart.bill} р.</h3></span>
                : 
                <>
                <Box sx={{ display: 'flex',   alignItems: 'center', justifyContent: 'center', flexDirection:'column'}}  className={s.containerBlancImg}>
                    <img src='./img/icon-512x512.png' alt='' />
                    <span><h2>Ваша корзина пуста</h2></span>
                </Box>
                </>
                }
            </div>

            {/* списко товаров */}
            {/* margin-bottom: 160px; */}
            {/* sx = {{marginBottom: height  * 2 + 15 + "px"}} */}
            { (magOption !== 'stuf') ?
            <Grow in={true} style={{ transformOrigin: '0 0 0' }} timeout = { delayForAnimation }>
            <Paper elevation={1} className = {s.ListContainer} sx = {{marginBottom: height  * 2 + 15 + "px"}}>
                {/* <ListItem component="ul"> */}
                {props.stateCart.cart.map((item, index) => (
                    <RowItemCart key = {index} itemInc = {itemInc} itemDec = {itemDec} item = {item} index = {index}/>
                ))}
                {/* </ListItem> */}
            </Paper>
            </Grow>
            :
            <Grow in={true} style={{ transformOrigin: '0 0 0' }} timeout = { delayForAnimation }>
            <Paper elevation={1} className = {s.ListContainer}>
                {/* <ListItem component="ul"> */}
                {props.stateCart.cart.map((item, index) => (
                    <RowItemCart key = {index} itemInc = {itemInc} itemDec = {itemDec} item = {item} index = {index}/>
                ))}
                {/* </ListItem> */}
            </Paper>
            </Grow>
            }
            {/* тут указываем номер стола, если есть magOption == 'stuf' */}
            { ((magOption === 'stuf') && (props.stateCart.count > 0))?
            <Grow in={true} style={{ transformOrigin: '0 0 0' }} timeout = { delayForAnimation }>
            <Paper elevation={1} className = {s.stuf} sx = {{marginBottom: height  * 2 + 15 + "px"}}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Стол</InputLabel>
                    <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={table}
                    label="Стол"
                    onChange={handleChangeTable}
                    >
                    {/* <MenuItem value={1}>Первый</MenuItem> */}
                    {/* <MenuItem value={2}>Второй</MenuItem> */}
                    {/* <MenuItem value={3}>Третий</MenuItem> */}
                    {
                        Array(Number(maxTable)).fill(false).map((x, index) =>
                            <MenuItem value={index + 1} key={index + 1}>{index + 1}</MenuItem>
                          )
                    }
                    </Select>
                </FormControl>
            </Paper>
            </Grow>
            : null
            }
            {/* тут кнопка купить за. Кнопка прибита к подвалу страницы 60*/}
            { props.stateCart.count > 0 ?
            <Grow in={true} style={{ transformOrigin: '0 0 0' }} timeout = {delayForAnimation}>
            <Paper sx={{ position: 'fixed', bottom: height, left: 0, right: 0 }} elevation={3} className={s.BottomNavigation}>
                <BottomNavigation sx={{height: '45px'}}>
                    <BottomNavigationAction className={s.BottonAction} onClick={() => sendCart()} showLabel={true} label={"Оформить за " + props.stateCart.bill + " р."} value="list" />
                </BottomNavigation>
            </Paper>
            </Grow>
            : 
            <Grow in={true} style={{ transformOrigin: '0 0 0' }} timeout = {delayForAnimation}>
            <Paper sx={{ position: 'fixed', bottom: height, left: 0, right: 0 }} elevation={3} className={s.BottomNavigationD}>
            <BottomNavigation sx={{height: '45px'}}>
                <BottomNavigationAction className={s.BottonAction} onClick={() => backMain()} showLabel={true} label={"Продолжить покупки"} value="list" />
            </BottomNavigation>
            </Paper>
            </Grow>
            }
        </div>
    )
}

export default Pagecart;
// корзина
// кнопки увеличения и увеньшенния количетсва по строке(при уменьшении 0 и ниже, происходит удаление)