// icons
// https://www.flaticon.com/search?word=pizza&shape=outline&order_by=4
// оптимизация SVG
// https://svgoptimizer.com/ru/
// flex с горизонтальной прокруткой
// https://tproger.ru/translations/horizontal-scroll-flexbox/
// нормально будет работать только при количестве до 100к товаров! Или нужно исправлять формирование KEY в списке
// жизненный цикл
// https://otus.ru/nest/post/2116/
// https://habr.com/ru/post/353564/


import { React } from 'react';
import { useEffect } from 'react';
// import {  useCallback, useMemo } from 'react';
import s from './Listitems.module.css';

import ListItem from "@mui/material/ListItem";

// import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
// import { propsToClassKey } from '@mui/styles';
// import { useNavigate } from "react-router-dom";
// import { Navigate } from "react-router-dom";
// import Radium, { StyleRoot } from 'radium';
// import Radium from 'radium';
// import { StyleSheet, css } from 'aphrodite';
import ListItemsRow from "./ListItemsRow";

import { startAnimationlocal } from "../common/utils";
import { delayForAnimation } from '../../components/common/utils';
// import { getOffsetRect } from "../common/utils";

// startAnimation("cardmedia-" + props.item.id);

const Listitems = (props) => {

  const { lastinsertitem }  = props;
  // const { rect }            = props;
  const { lclear }          = props;
  const { laddCart }        = props;
  // const { updaterect }      = props;
  const { chipData }        = props;  
  const { itemsData }       = props;  

  // первый символ в строке заглавной буквой - это для наименвоания групп
  const firstCap = (s) => (
    s.charAt(0).toUpperCase() + s.slice(1)
  )
  
  // const makeStylel = (ofset) => {

  // const bounce = {

  //   "5%" : {
  //     transform: "translate3d(0, 5px, 0)"
  //   },
  //   "10%" : {
  //     transform: "translate3d(0, -10px, 0)"
  //   }, 
  //   "20%" : {
  //     transform: "translate3d(0, -10px, 0)"
  //   },
  //   "to" : {
  //     opacity: "0,3",
  //     transform: "translate3d(550px, 1800px, 0)"
  //   }

  // }

  // const makeStylel = StyleSheet.create({
  //   amin1: {
  //     animationName: bounce,
  //     animationDuration: '2s',
  //     animationIterationCount: '1',
  //   }
  // });
  // }
    // const moveOffsety = window.parent.innerHeight;
    // const moveOffsety = String(ofset);
    // const moveOffsetx = window.parent.innerWidth * 0.85;

    // const an = Radium.keyframes({

    //   "15%": {
    //       "transform": "translate3d(0, 5px, 0)"
    //   },
    //   "30%": {
    //       "transform": "translate3d(0, -10px, 0)"
    //   },
    //   "45%": {
    //       "transform": "translate3d(0, -10px, 0)"
    //   },
    //   "to": {
    //       "opacity": 0.5, 
    //       "transform": `translate3d(${moveOffsetx}px, ${moveOffsety}px, 0)`
    //   }

    // });
    
    // const animationNameL = an.__process().animationName;

    // console.log(an);

    // return (an.__process().animationName)
  // }

  // добавляем в корзину или открываем окно с выбором вариантов или дополнительных ингридиентов
  // решил прсото перекинуть props
  const smartAdd = (item) => {
      // обернули быструю продажу
      // тут резервная анимация для списка 
      // console.log(item);
      lclear();      
      laddCart(item);
      // var s1 = makeStyle(250);
      // console.log(s1);
      // startAnimation("cardmedia-" + item.itemData.id, s1);
      // console.log(getOffsetRect(document.getElementById("cardmedia-" + item.itemData.id)));
  }    

  // console.log(props);
  // var ss1 = makeStylel(250);

  useEffect(() => {
    // console.log("starting effect ...");
    if (lastinsertitem > 0) {
      // console.log("starting anomation ...");
      // console.log(lastinsertitem);
      lclear();
      // var s1 = makeStylel(250);
      setTimeout(() => {
        // нужно задержка т.к. Pagelist Grow timeout = {delayForAnimation}
        // startAnimationlocal("cardmedia-" + lastinsertitem, rect, 'animation-css-frames');
        startAnimationlocal("cardmedia-" + lastinsertitem, 'animation-css-frames');
      }, delayForAnimation);

    }
    // return (() => {
    //   console.log("effect clear ...");
    //   lclear();
    // })

  }, [ lclear, lastinsertitem ] );
  // }, [ lastinsertitem, rect, lclear ] );

  return (
    <div className = {s.containerListItems} sx={{ backgroundColor: 'background.default' }}>
      {/* <Navigate to="/dashboard" />; */}
      
      {chipData.map((group, indexg) => {
        
          let itemInGroup = itemsData.filter((itemf)=> {
            return (itemf.group === group.label)
          });
          
          // console.log("группа => " + group.label);
          // console.log(itemInGroup);  

          let i = itemInGroup.map((item, index) => {
            return (
                <div key={(indexg + 1) * 100000 + index} data-id = {item.id}>
                { index === 0 ? 
                  <>
                  {/* наименование группы */}
                  <Typography variant="h6" id = {"SGroup" + indexg}>{firstCap(group.label)}</Typography>
                  {/* <ListItem component="ul" id = {"SGroup" + indexg} data-text = {group.label}> */}
                  <ListItem component="ul" data-text = {group.label}>
                    <ListItemsRow 
                      // lastinsertitem = {props.lastinsertitem}
                      // clearLastInsertItem = {props.lclearLastInsertItem}
                      item = {item} 
                      // navigate = {navigate} 
                      laddCart={(item) => {smartAdd(item)}}
                      // updaterect={updaterect}
                      // laddCart={(item) => {this.props.laddCart(item)}}
                    />
                  </ListItem>
                  </>
                : 
                  <ListItem component="ul">
                    <ListItemsRow 
                      // lastinsertitem = {props.lastinsertitem}
                      // clearLastInsertItem = {props.lclearLastInsertItem}
                      item = {item} 
                      // navigate = {this.navigate} 
                      laddCart={(item) => {smartAdd(item)}}
                      // updaterect={updaterect}
                      // laddCart={(item) => {this.props.laddCart(item)}}
                    />
                  </ListItem>
                }
                </div>
            );  
          });
          // console.log(i);
          // непосредственно вывод, что получается после map
          return(
            i
            );

      })}

    </div>
  );
}

// const Listitemsl = Radium(Listitems)
export default Listitems;
