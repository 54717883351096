// icons
// https://www.flaticon.com/search?word=pizza&shape=outline&order_by=4
// оптимизация SVG
// https://svgoptimizer.com/ru/
// flex с горизонтальной прокруткой
// https://tproger.ru/translations/horizontal-scroll-flexbox/

import React from 'react';
import s from './Menuchip.module.css';
import { useTheme } from '@mui/material/styles';

import Chip from "@mui/material/Chip";
import ListItem from "@mui/material/ListItem";

const Menuchip = (props) => {

    let {chipData} = props;
    const Theme = useTheme();
    let backgroundBody = Theme.palette.background.default;
    // let backgroundBody = window.getComputedStyle( document.body ,null).getPropertyValue('background-color');

    let scrollToMyRef = (id, self) => {

      // console.log(self);
      const el = document.getElementById(id);
      const elm = document.getElementsByClassName(self);

      const Rect = el.getBoundingClientRect();
      const RectM = elm[0].getBoundingClientRect();
      // не учитывает верхнее меню
      // el.scrollIntoView({block:"center", behavior: "smooth"});
      // console.log(RectM);

      window.scrollBy({
              top: Rect.top - RectM.height,
              left: 0,
              behavior: "smooth"
      });

    }

      return (
        <div className = {s.containerMenuchip} style={{ backgroundColor: backgroundBody }}>
          
          {chipData.map((data, index) => {
    
            return (
              <ListItem key={data.key} component="ul">
                <Chip
                  label={data.label}
                  onClick = {(e) => {
                      setTimeout(scrollToMyRef("SGroup" + index, s.containerMenuchip), 200);
                  }}
                />
              </ListItem>
            );
          })}

        </div>
      ); 
}

export default Menuchip;
