import React from 'react';
import ItemCard from "./ItemCard";
import {connect} from "react-redux";
// import {logout} from "../../redux/auth-reducer";
import {addItemInCart, updateItemInCart} from "../../redux/app-reducer";
import { getData } from 'api/api';
import { useParams } from "react-router";

// class ItemCardContainer extends React.Component {

//     componentDidMount() {
//         // this.props.getAuthUserData();
//         // посмотреть State
//         // let {actionMode} = this.props; // addInCart editInCart
//         let GetItem = (id) => getData.item(id);
//         // const params = useParams();
//         // const ItemData = GetItem(Number(params.id));
//         // console.log(this.props); 
//         console.log(this.props); 
//     }

//     render() {
//         return <ItemCard {...this.props} />
//     }
// }

const ItemCardContainer = (props) => {
    
    // console.log(props); 
    
    const params = useParams();
    var ItemData = {};
    var chosen = 0;
    var chosenEx = [];
    var TextButton = '';
    var remaind = 0;

    // для добавления нового
    if (props.actionMode === "addInCart") {
        let GetItem = (id) => getData.item(id);
        ItemData = GetItem(Number(params.id));
        chosen = 0;
        let count = (Object.keys(ItemData).includes('extensions')) ? ItemData.extensions.length : 0;
        chosenEx = Array(count).fill(false);
        TextButton = 'Купить за ';
    }

    // для редактирования из корзины editInCart
    if (props.actionMode === "editInCart") {
        ItemData = props.state.cart[Number(params.id)].itemData;
        chosen = props.state.cart[Number(params.id)].chosen;
        chosenEx = props.state.cart[Number(params.id)].chosenEx;
        remaind = props.state.cart[Number(params.id)].remaind;
        TextButton = 'Обновить на ';
    }

    return (
        <ItemCard {...props} 
            ItemData = {ItemData} 
            chosen = {chosen} 
            chosenEx = {chosenEx} 
            TextButton = {TextButton} 
            id = {Number(params.id)}
            remaind = {remaind}
            />
    )
}

let mapStateToProps = (state) => {
    return {
      state: state.app,
      rect: state.app.rect,
    }
  }
  
let mapDispatchToProps = (dispatch) => {
    return {
        laddCart: (Item) => {
            dispatch(addItemInCart(Item));
        },
        lupdateCart: (Item) => {
            dispatch(updateItemInCart(Item));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (ItemCardContainer);
