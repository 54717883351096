// https://thetasty.space/
// https://svgoptimizer.com/ru/


import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// import {BrowserRouter as Router} from "react-router-dom";
import {HashRouter as Router} from "react-router-dom";

import {Provider} from "react-redux";
import store from './redux/redux-store';

import theme from './components/common/theme';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { createBrowserHistory } from 'history';
import { initFB, makeRecaptchaVerifier } from "./components/common/firebase";
// import { StyleRoot } from 'radium';

// import { StyledEngineProvider } from '@mui/material/styles';

const history = createBrowserHistory();

const root = ReactDOM.createRoot(document.getElementById('root'));

// for firebase
initFB();
// sign-in-button в index.html
makeRecaptchaVerifier('sign-in-button');
// for firebase

root.render(
  <React.StrictMode>
  <ThemeProvider theme={theme}>
  {/* <StyleRoot> */}
  <CssBaseline />
    <Router history = {history} >
    {/* <BrowserRouter> */}
      <Provider store={store}>
        {/* <StyledEngineProvider injectFirst> */}
          <App />
        {/* </StyledEngineProvider> */}
      </Provider>
    </Router>
    {/* </StyleRoot> */}
  </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
