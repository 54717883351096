// icons
// https://www.flaticon.com/search?word=pizza&shape=outline&order_by=4
// оптимизация SVG
// https://svgoptimizer.com/ru/
import React from 'react';
import s from './Footer.module.css';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import Badge from '@mui/material/Badge';

import { SvgIcon } from '@mui/material';
import { ReactComponent as List } from "../../assets/icon/list-min.svg";
import { ReactComponent as ProfileW } from "../../assets/icon/profile-w-min.svg";
// import { ReactComponent as ProfileM } from "../../assets/icon/profile-m-min.svg";
import { ReactComponent as Contact } from "../../assets/icon/contact-min.svg";
import { ReactComponent as Cart } from "../../assets/icon/cart-min.svg";

import Slide from '@mui/material/Slide';

import {useNavigate} from "react-router-dom";
import {useLocation} from "react-router-dom";

const Footer = (props) => {

  // const el = React.createRef();

  const [checked, setChecked] = React.useState(true);
  const [value, setValue] = React.useState('list');
  let navigate = useNavigate();
  let location = useLocation();

  React.useEffect(() => {
    
    // console.log("effect from footer ...");

    if (location.pathname.substring(1) !== value && location.pathname.length > 3) {
      setValue(location.pathname.substring(1));
      // console.log("move menu");
    }

    if ((location.pathname.substring(0,5) !== "/item") && (location.pathname.substring(0,7) !== "/tuning")) {
      setChecked((prev) => true)
    } else {
      setChecked((prev) => false)
    }
    // console.log(location);
  }, [value, location]);

  const handleChange = (event, newValue) => {
    // console.log(newValue);
    setValue(newValue);
  };

  let ListIcon = (props) => {
    return (
      //0 0 600 476.6
      <SvgIcon component={List} viewBox="0 0 500 500" />
    )
  }
  
  let ProfileIcon = (props) => {
    return (
      <SvgIcon component={ProfileW} viewBox="0 0 500 500" />
    )
  }

  let ContactIcon = (props) => {
    return (
      <SvgIcon component={Contact} viewBox="0 0 500 500" />
    )
  }

  let CartIcon = (props) => {
    return (
      <SvgIcon component={Cart} viewBox="0 0 500 500" />
    )
  }
  
  //значение из State 
  // console.log(props.count);

  return (
      <Slide direction="up" in={checked} mountOnEnter unmountOnExit>
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex:1 }} elevation={3} id="paper-footer" className={s.paperfooter}>
      <BottomNavigation value={value} onChange={handleChange} className={s.BottomNavigation}>
          <BottomNavigationAction className={s.Grayscale} onClick={() => navigate("/list")} showLabel={true} label="Меню" value="list" icon={<ListIcon />} />
          <BottomNavigationAction className={s.Grayscale} onClick={() => navigate("/profile")} showLabel={true} label="Профиль" value="profile" icon={<ProfileIcon />} />
          <BottomNavigationAction className={s.Grayscale} onClick={() => navigate("/contact")} showLabel={true} label="Контакты" value="contact" icon={<ContactIcon />} />
          <BottomNavigationAction className={s.Grayscale} onClick={() => navigate("/cart")} showLabel={true} label="Корзина" value="cart" icon={
            props.count === 0 ? <CartIcon /> : <Badge badgeContent={props.count} color="primary"> <CartIcon /> </Badge>
          } />
      </BottomNavigation>
      </Paper>
      </Slide>
  )
}

export default Footer;
