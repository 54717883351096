import React from 'react';
import Pagecart from "./Pagecart";
import {connect} from "react-redux";
// import {logout} from "../../redux/auth-reducer";
import {incItemInCart} from "../../redux/app-reducer";
import {decItemInCart} from "../../redux/app-reducer";

class PagecartContainer extends React.Component {

    componentDidMount() {
    //     this.props.getAuthUserData();
    // посмотреть State
        // console.log(this.props);
    }

    render() {
        return <Pagecart {...this.props} />
    }
}

const mapStateToProps = (state) => ({
    stateCart: state.app,
    stateCfg: state.config,
});

const mapDispatchToProps = (dispatch) => {
    return {
        itemInc: (Item) => {
            dispatch(incItemInCart(Item));
        },
        itemDec: (Item) => {
            dispatch(decItemInCart(Item))
        }
    }
}

// export default connect(mapStateToProps) (PagecartContainer);
export default connect(mapStateToProps, mapDispatchToProps) (PagecartContainer);