import * as axios from "axios";

const instance = axios.create({
    withCredentials: true,
    baseURL: 'https://social-network.samuraijs.com/api/1.0/',
    headers: {
        "API-KEY": "3120ef6f-e518-49cd-b9b6-f77e96cc6f49"
    }

});

// console.log(process.env.REACT_APP_WEB_ROOT_SITE);
// это своя загрузка из локальной публикации )
const instanceBASE  = axios.create({
    baseURL: process.env.REACT_APP_WEB_ROOT_SITE,
    headers: {
        "API-KEY": "3120ef6f-e518-49cd-b9b6-f77e96cc6f49"
    }
});

// var localdata = require('assets/data/data.json');
// var favlocaldata234x60 = require('assets/data/favdata234x60.json');
// var favlocaldata120x90 = require('assets/data/favdata120x90.json');

// var localdata = window.localdata;
// var favlocaldata234x60 = window.favlocaldata234x60;
// var favlocaldata120x90 = window.favlocaldata120x90;

export const getDataSite = (s)  => { 
    // console.log(s)
    return ([
        instanceBASE.get(`${s}data/data.json`),
        instanceBASE.get(`${s}data/favdata234x60.json`),
        instanceBASE.get(`${s}data/favdata120x90.json`),
    ])
}

export const usersAPI = {
    getUsers(currentPage = 1, pageSize = 10) {
        return instance.get(`users?page=${currentPage}&count=${pageSize}`)
            .then(response => {
                return response.data;
            });
    },
    follow(userId) {
        return instance.post(`follow/${userId}`)
    },
    unfollow(userId) {
        return instance.delete(`follow/${userId}`)
    },
    getProfile(userId) {
        console.warn('Obsolete method. Please profileAPI object.')
        return profileAPI.getProfile(userId);

    }
}

export const profileAPI = {
    getProfile(userId) {
        return instance.get(`profile/` + userId);
    },
    getStatus(userId) {
        return instance.get(`profile/status/` + userId);
    },
    updateStatus(status) {
        return instance.put(`profile/status/`, { status: status });
    }
}

export const authAPI = {
    me() {
        return instance.get(`auth/me`);
    },
    login(email, password,rememberMe = false) {
        return instance.post(`auth/login`, {email, password,rememberMe});
    },
    logout() {
        return instance.delete(`auth/login`);
    }
}


export const getData = {
    
    chip() {
        // Получить все группы
        const groups = window.localdata.reduce((acc, item) => {
            if ( !acc.includes(item.group) ) {
                acc.push(item.group);
            }
            return acc;
        }, []);

        // console.log(groups);

        let chipData = groups.map((item, index) => {
            return ({key:index, label:item})
        });

        return (chipData);
    },

    fav234x60() {
        // получить объект fav
        const favData = window.favlocaldata234x60;
        return (favData);
    },
    
    fav120x90() {
        // получить объект fav
        const favData = window.favlocaldata120x90;
        return (favData);
    },

    items(){
        return(window.localdata);
    },

    itemsf(){
        // получить весь объект data
        // тут нужно добавить фильтрацию!!!
        let d = window.localdata.filter((itemf)=> {
            return (!Object.keys(itemf).includes('disabled'))
        });

        // return(localdata);
        return(d);
    },

    find(text) {
        // поиск по базе данных из регулярного выражения (ищем по title и description)
        const re = new RegExp(text.toLowerCase());
        
        let a1 = window.localdata.filter((item) => {
            if (!Object.keys(item).includes('disabled')) {
                return(re.test(JSON.stringify({id: item.id,  title:item.title, description: item.description}).toLowerCase()));
            } else {
                return (null);
            }
        });
        
        return(a1);
    },

    item(id) {
        // получить экземпляр данных из объекта data
        let d = window.localdata.filter((itemf)=> {
                return (itemf.id === id)
            });
        return(d[0]);
    }
}
