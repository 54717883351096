// https://habr.com/ru/company/kts/blog/598835/
// https://www.javatpoint.com/browserrouter-in-react
// оптимизация изображений
// https://svgoptimizer.com/ru/


import React from 'react';
// import {Route, withRouter} from "react-router-dom";
import {Route, Routes} from "react-router-dom";
import './App.css';
import {connect} from "react-redux";
import {compose} from "redux";
import {initializeApp} from "./redux/app-reducer";
import Preloader from "./components/common/Preloader/Preloader";
import Footer from './components/Footer/FooterContainer';
// import ItemCard from "./components/ItemCard/ItemCard";
import ItemCardContainer from "./components/ItemCard/ItemCardContainer";
import PageprofileContainer from "./Pages/Pageprofile/PageprofileContainer";
import PagelistContainer from "./Pages/Pagelist/PagelistContainer";
import Pagecontact from "./Pages/Pagecontact/Pagecontact";
import PagecartContainer from "./Pages/Pagecart/PagecartContainer";
import PagesearchContainer from "./Pages/Pagesearch/PagesearchContainer";

// import Slide from '@mui/material/Slide';

class App extends React.Component {

    props = super.props;
    
    componentDidMount() {
            this.props.initializeApp();
    }

    render() {
        
        if (!this.props.initialized) {
            return <Preloader/>
        }

        // console.log(this.props);

        return (
            <div className='app-wrapper'>
                {/* <HeaderContainer/> */} 
                {/* <NavBar/> */}
                <div className='app-wrapper__content'>
                <Routes>
                    <Route path='/' element={<PagelistContainer/>}/>
                    <Route path='/list' element={<PagelistContainer />}/>
                    <Route path='/search' element={<PagesearchContainer />}/>
                    <Route path='/profile' element={<PageprofileContainer/>}/>
                    <Route path='/contact' element={<Pagecontact/>}/>
                    <Route path='/cart' element={<PagecartContainer/>}/>
                    <Route path='/item/:id' element={<ItemCardContainer actionMode = "addInCart"/>}/>
                    <Route path='/tuning/:id' element={<ItemCardContainer actionMode = "editInCart"/>}/>
                    <Route path='*' element={<PagelistContainer/>}/>
                </Routes>
                </div>  
                {/* <Slide direction="down" in={checked} mountOnEnter unmountOnExit> */}
                {/* <Slide direction="down"> */}
                    <Footer/>                         
                {/* </Slide> */}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    initialized: state.app.initialized
})

export default compose(
    // withRouter,
    connect(mapStateToProps, {initializeApp})) (App);

