// import {getAuthUserData} from "./auth-reducer";

const INITIALIZED_SUCCESS = 'INITIALIZED_SUCCESS';
const UPDATE_CFG = 'UPDATE_CFG';
const UPDATE_USER = 'UPDATE_USER';

let initialState = (localStorage['stor-cfg']) ? JSON.parse(localStorage['stor-cfg']) : {
    magOption:'vface', // el-menu vface stuf
    themePalette:'dark', // 0 - dark, 1 - light
    maxTable:2,
    user:{}
};


// reducer
const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case INITIALIZED_SUCCESS:
            return {
                ...state,
                // initialized: true
            }
        case UPDATE_CFG:
            // console.log(state);
            // console.log(action);
            let s = (action.value.checked)? 'dark' : 'light';
            return {
                ...state,
                magOption: action.value.magOption,
                maxTable: action.value.maxTable,
                themePalette: s,
            }
            case UPDATE_USER:
                // console.log(state);
                // console.log(action);
                if (action.value.uid) {
                    // console.log(1);
                    return {
                        ...state,
                        user: action.value,
                    } 
                } else {
                    let u = state.user;
                    u.signed = 0;
                    // console.log(2);
                    // console.log(u);
                    return {
                        ...state,
                        user: u,
                    }
                }
        default:
            return state;
    }
}

// actions
const initializedSuccess = ()   => ({type: INITIALIZED_SUCCESS });
const updateCfg = (item)  => ({type: UPDATE_CFG, value: item});
const updateUser = (user)  => ({type: UPDATE_USER, value: user});

export const initializeApp = () => (dispatch) => {
    dispatch(initializedSuccess());
}

export const updateCfgApp = (item) => (dispatch) => {
    dispatch(updateCfg(item));
}

export const updateCfgUser = (user) => (dispatch) => {
    // console.log("69");
    dispatch(updateUser(user));
}

export default appReducer;
