// icons
// https://www.flaticon.com/search?word=pizza&shape=outline&order_by=4
// оптимизация SVG
// https://svgoptimizer.com/ru/
// flex с горизонтальной прокруткой
// https://tproger.ru/translations/horizontal-scroll-flexbox/
// нормально будет работать только при количестве до 100к товаров! Или нужно исправлять формирование KEY в списке
import React from 'react';
import s from './ItemCard.module.css';
// import ListItem from "@mui/material/ListItem";
// import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
// import BottomNavigation from '@mui/material/BottomNavigation';
// import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { useNavigate } from "react-router-dom";
// import { useLocation } from "react-router-dom";
// import { useParams } from "react-router";
import ItemExtention from 'components/ItemExtention/ItemExtention';
import ItemChoice from 'components/ItemChoice/ItemChoice';
// import { getData } from 'api/api';
import Badge from '@mui/material/Badge';
import Grow from '@mui/material/Grow';
// import {connect} from "react-redux";
// import {compose} from "redux";
// import {addItemInCart} from "../../redux/app-reducer";
// import { getOffsetRect } from "../common/utils";
import { delayForAnimation } from '../../components/common/utils';

const ItemCard = (props) => {
      let {actionMode} = props;
      // console.log(props); // addInCart editInCart
      // let {chipData} = props;
      // let {itemsData} = props;
      // let GetItem = (id) => getData.item(id);
      
      const navigate = useNavigate();
      // const params = useParams();

      // const ItemData = GetItem(Number(params.id));
      const {ItemData} = props;

      const [ chosen, setСhosen ] = React.useState(props.chosen);
      
      let count = (Object.keys(ItemData).includes('extensions')) ? ItemData.extensions.length : 0;
      // let count = ItemData.extensions.length;
      // ItemData который выбран для страницы
      // console.log(ItemData);

      // const [ chosenEx, setСhosenEx ] = React.useState(Array(ItemData.extensions.length).fill(false));
      // const [ chosenEx, setСhosenEx ] = React.useState(Array(count).fill(false));
      const [ chosenEx, setСhosenEx ] = React.useState(props.chosenEx);

      // chosenEx.fill(false);
      // console.log(chosenEx);

      // формирование стоимости позиции товара для чека
      const CalcCost = (chosenEx, chosen, ItemData) => {
        // стоимость без Extantion
        let lsumma = (Object.keys(ItemData).includes('choice')) ? Number(ItemData.choice[chosen].price) : Number(ItemData.price);
        let lsumma_dop = 0;
        if (Object.keys(ItemData).includes('extensions')) {
          lsumma_dop = chosenEx.reduce((s, item, index) => {
              if (item === true) {
                s += Number(ItemData.extensions[index].price);
              }
            return s;
          }, 0);
        } 
        // контроль расчета стоимости (выбор по вида и дополнительных элементов)
        // console.log(lsumma + "==" + lsumma_dop);
        return (lsumma + lsumma_dop);
      }

      // получение начальной стоимости
      // const [ cost, setCost ] = React.useState(CalcCost(Array(count).fill(false),0,ItemData));
      const [ cost, setCost ] = React.useState(CalcCost(chosenEx, chosen, ItemData));

      // установка и снятие допов на позицию
      const handleChange = (index) => {
        let newState = chosenEx.map((item, indexy) => (
          (index === indexy) ? !item: item
        )); 
        setCost(CalcCost(newState, chosen, ItemData));
        setСhosenEx(newState);
        // console.log(chosenEx);
      };

      // let backgroundBody = window.getComputedStyle( document.body ,null).getPropertyValue('background-color');
      // сбросить все Extention 
      const nullEx = () => {
        let newState = Array(count).fill(false);
        setСhosenEx(newState);
        setCost(CalcCost(newState, chosen, ItemData));
      }

      // вернуться на страницу (назад на одну)
      const backMain = () => {
        navigate(-1);
      }
      
      // добавить товар в чек
      const addGoods = () => {
        // console.log(document.getElementById("cardmedia-" + ItemData.id));
        // let rect = getOffsetRect(document.getElementById("cardmedia-" + ItemData.id));
        let { rect } = props;
        let product = {"itemData": ItemData, "chosen":chosen, "chosenEx":chosenEx, "cost":cost, "remaind":1, "rect":rect}
        // console.log(product);
        if (actionMode === "editInCart") { 
          props.lupdateCart({...product, "lid" :props.id, "remaind":props.remaind, "cost":cost * props.remaind});
        } else {
          props.laddCart(product); // laddCart lupdateCart
        }
        // вернуться к выбору
        backMain();
      }

      // принудительно прокрутить в самое начало страницу, только при первом монтировании
      React.useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

      // определение бэйджа SUPER
      let superItem = Object.keys(ItemData).includes('super') ? true : false;
      // console.log(superItem);

      return (
        <div className = {s.containerItem} sx={{ backgroundColor: 'background.default' }}>
          <Box sx={{ flexGrow: 1 }}>
            <AppBar position="fixed"> {/*static fixed*/}
              <Toolbar>
                <IconButton
                  onClick={backMain}
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                >
                  <ArrowBackIosIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
          </Box>
          {/* <Grow in={true} style={{ transformOrigin: '0 0 0' }} timeout = {500}> */}
          <div sx = {{textAlign: 'center'}} className = {s.mainImageContainer}>
            {/* тут картинка и ХИТ*/}
            <Badge badgeContent={"хит"} invisible={!superItem} color="primary" sx={{justifyContent: 'center'}}>
              <img className = {s.mainImage} src={"/goods/" + ItemData.img} alt="" loading="lazy"/>
            </Badge>
          </div>
          {/* </Grow>  */}
          <Grow in={true} style={{ transformOrigin: '0 0 0' }} timeout = {delayForAnimation}>
          {/* тут название тозиции */}
          <div className = {s.mainTitleContainer}>
            {/* <span>{ItemData.group.charAt(0).toUpperCase() + ItemData.group.slice(1)}: </span> */}
            <span>{ItemData.title}</span>
          </div>
          </Grow>
          {/* тут описание, что выбрано по размеру и цене*/}
          {(Object.keys(ItemData).includes('choice'))?
            <div className = {s.mainСhoose}>
              <span>Размер: {ItemData.choice[chosen].size} см. Вес: {ItemData.choice[chosen].weight} г.</span>
            </div>
          :
            <div className = {s.mainСhoose}>
              <Typography variant="subtitle1" color="text.secondary" component="div" sx={{fontSize : "14px"}}>
                <span>Вес: {ItemData.weight} г.</span>
              </Typography>
              {/* <h5>{ItemData.price}</h5> */}
              {/* <h5>{ItemData.weight}</h5> */}
            </div>
          }
          {/* Описание и состав */}
          <Grow in={true} style={{ transformOrigin: '0 0 0' }} timeout = {delayForAnimation}>
          <div className = {s.mainDesriptionContainer}>
            {/* описание товара */}
            <Typography variant="subtitle1" color="text.secondary" component="div" sx={{fontSize : "14px"}}>
              <span>{ItemData.description + '\n'}</span>
            </Typography>
            {(Object.keys(ItemData).includes('composition'))?
            // состав товара
            <Typography variant="subtitle1" color="text.secondary" component="div" sx={{fontSize : "14px"}}>
              <span>Состав: {ItemData.composition}</span>
            </Typography>
            : null
            }
          </div>
          </Grow>
          {/* работа с выбором */}
          <ItemChoice ItemData = {ItemData} chosen = {chosen}  setСhosen = {(s) => {
            setСhosen(s);
            // console.log(CalcCost(ItemData.extensions));
            // nullEx();
            setCost(CalcCost(chosenEx, s, ItemData));
          }}/>
          {/* тут дополнительыне ингридиенты */}
          {(Object.keys(ItemData).includes('extensions'))?
          <Grow in={true} style={{ transformOrigin: '0 0 0' }} timeout = {delayForAnimation}>
          <div className = {s.mainChoiceContainerEx}>
            {/* тут строка - убрать ингридиенты */}
            <div>
            <Typography variant="subtitle1" color="text.secondary" component="div" sx={{fontSize : "14px"}}>
              <span onClick = {() => nullEx()}>Убрать ингридиенты ...</span>
            </Typography>
            </div>
            <Box
              sx={{
                display: 'grid',
                columnGap: 1,
                rowGap: 1,
                gridTemplateColumns: 'repeat(3, 1fr)',
              }}
            >
            {
              ItemData.extensions.map((ext, index) => {
              // var ch = (index === 0 ? true : false)
              // chosenEx = {ch}
              // фильтрация на наличие закрытых ингридиентов
              if (!Object.keys(ext).includes('disabled')) {
                return (
                  <ItemExtention key = {index} { ...ext} chosenEx = {chosenEx[index]} handleChange = {() => handleChange(index)}/>
                )
              } else {
                return null;
              }
            })
            }
            </Box>          
          </div>
          </Grow>
          : null }
          {/* тут кнопка купить за. Кнопка прибита к подвалу страницы */}
          <Grow in={true} style={{ transformOrigin: '0 0 0' }} timeout = {delayForAnimation}>
          <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3} id="paper-footer-item-card">
          {/* <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}> */}
            {/* <BottomNavigation className={s.BottomNavigation}> */}
            {/* <Box className={[s.fix1, s.BottomNavigation]}> */}
            <Box className={[s.fixBottomNavigation, s.BottomNavigation]}>
              {/* <BottomNavigationAction className={s.BottonAction} onClick={() => addGoods()} showLabel={true} label={props.TextButton + cost + " р."} value="list" /> */}
              <Button variant="outlined" fullWidth={true} className={s.BottonAction} onClick={() => addGoods()}>
                {props.TextButton + cost + " р."}
              </Button>
            {/* </BottomNavigation> */}
            </Box>
          </Paper>
          </Grow>
        </div>
      ); 
}

export default ItemCard;
// сформировать стейджинг для позиции в чек (пока позиции без группировки)
