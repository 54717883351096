import React from 'react';
import s from './Pagecontact.module.css';
import { Grow } from '@mui/material';
import { Paper } from '@mui/material';
import { Button, Typography } from '@mui/material';
import { delayForAnimation } from '../../components/common/utils';

const Pagecontact = () => {
    return (     
        <Grow
        in={true}
        style={{ transformOrigin: '0 0 0' }}
        // timeout = {500}
        timeout = {delayForAnimation}
        >        
            <div className = {s.container}>
                <Paper elevation={0} className = {s.pagecontact}>
                    <h3>Контакты</h3>
                </Paper>   
                
                <Paper elevation={0}>  
                <Typography variant="subtitle1" color="text.secondary" component="div" sx={{fontSize : "14px"}}>
                {/* <Typography variant="subtitle1" component="div" sx={{fontSize : "14px"}}> */}
                    <span>Друзья, поделитесь с нами мнением об обслуживании, идеями и пожеланиями. 
                        Мы каждый день стараемся быть лучшими именно для Вас.
                    </span>
                    <br></br><br></br>
                    <span>Приятного аппетита, будем на связи ...</span>
                </Typography>
                </Paper>   

                <Paper elevation={1}>
                    <Button size="large" href="https://thetasty.space/"><h3>Наш сайт</h3></Button>
                </Paper>   

                <Paper elevation={1}>
                    <Button size="large" href="https://vk.com/thetastyspace"><h3>Наш VK</h3></Button>
                </Paper>   

                <Paper elevation={1}>
                    <Button size="large" href="tel:89000121206"><h3>+7 (900) 012-12-06</h3></Button>
                </Paper>   

                <Paper elevation={0}>
                    <Typography variant="subtitle1" color="text.secondary" component="div" sx={{fontSize : "14px"}}>
                        Ждем Вас, тут: <br></br>  г. Тверь, улица Вольного Новгорода, дом 1
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary" component="div" sx={{fontSize : "14px"}}>
                        г. Тверь, 1-я улица Трусова, дом 2
                    </Typography>
                </Paper>

            </div>

        </Grow>        
    )
}

export default Pagecontact;