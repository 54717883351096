import React from 'react';
import s from './Pagelist.module.css';
import Menuchip from 'components/Menuchip/Menuchip';
import Menufav120x90 from 'components/Menufav/Menufav120x90';
import Menufav234x60 from 'components/Menufav/Menufav234x60';
import ListitemsContainer from 'components/Listitems/ListitemsContainer';

import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

import Grow from '@mui/material/Grow';

import {getData} from 'api/api';
import { delayForAnimation } from '../../components/common/utils';
import { useNavigate } from "react-router-dom";

// import { startAnimationlocal } from "../common/utils";

const Pagelist = (props) => {
    
    const navigate = useNavigate();
    let chipData = getData.chip();
    let favDatafav234x60 = getData.fav234x60();
    let favDatafav120x90 = getData.fav120x90();
    // let items = getData.items();
    let items = getData.itemsf();

    const { updateItems } = props;

    // для анимации
    // const { lastinsertitem } = props;
    // const { rect } = props;
    // const { lclear } = props;
    // для анимации

    return (
        <Grow
          in={true}
          style={{ transformOrigin: '0 0 0' }}
        //   timeout = {500}
          timeout = {delayForAnimation}
        //  попробуем запускать отсюда анимацию
        //   addEndListener = {() => {
        //     // props.lastinsertitem
        //     console.log("end transition");
        //     console.log(props.lastinsertitem);
        //     if (lastinsertitem > 0) {
        //         lclear();
        //         // console.log("starting anomation ...");
        //         // setTimeout(() => {
        //           // нужно задержка т.к. Pagelist Grow timeout = {500}
        //           startAnimationlocal("cardmedia-" + lastinsertitem, rect);
        //         // }, 500);
        //       }
        //   }}
        >        
        <div>
            <div className={s.pagelist}>
                <h3>Tasty</h3>
                  <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    sx={{ mr: 2 }}
                    onClick={()=>{
                        navigate("/search");
                        updateItems([]);
                    }}
                  >
                  <SearchIcon />
                </IconButton>
            </div>
            <Menufav234x60 favData = {favDatafav234x60} {...props}/>
            <Menufav120x90 favData = {favDatafav120x90} {...props}/>
            <Menuchip chipData = {chipData}  {...props}/>
            <ListitemsContainer itemsData = {items} chipData = {chipData} {...props}/>
            <div className={s.bottom}></div>
        </div>
        </Grow>
    )
}

export default Pagelist;
