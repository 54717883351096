// import { red } from '@mui/material/colors';
//import { createMuiTheme } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';

// const themeLight = createTheme({
//   palette: {
//     primary: {
//       main: '#556cd6',
//     },
//     secondary: {
//       main: '#19857b',
//     },
//     error: {
//       main: red.A400,
//     },
//     background: {
//       default: '#fff',
//     },
//   },
// });

// var themePalette = 'dark'; //type dark light

let themePalette = (localStorage['stor-cfg']) ? JSON.parse(localStorage['stor-cfg']).themePalette : 'dark';

const theme = createTheme({
  palette: {
    // Switching the dark mode on is a single property value change.
    // mode: 'dark', //type dark light
    mode: themePalette,
  },
  
  // components: {
  //   MuiBottomNavigationAction: {
  //     styleOverrides: {
  //       root: {
  //         filter: "unset"
  //       }
  //     }
  //   }
  // }

});

export default theme;
