// стили
// https://habr.com/ru/post/441790/
// 234 на 60 баннер
// фактический размер для верстки 234 на 60
// вызывается из Pagelist

import React from 'react';
import s from './Menufav234x60.module.css';
import ListItem from "@mui/material/ListItem";
// import Button from "@mui/material/Button";

// import IM from "assets/icon/banner1.jpeg";

const Menufav234x60 = (props) => {

    let {favData} = props;

    let backgroundBody = window.getComputedStyle( document.body ,null).getPropertyValue('background-color');

    return (
        <div className = {s.containerMenufav} style={{ backgroundColor: backgroundBody }}>
          
          {favData.map((data) => {
    
            return (
              <ListItem key={data.key} component="ul">
                <div>
                  {/* <a href={data.url}> */}
                  <img src={data.img} alt='' onClick={ ()=> {
                      // alert(data.url);
                      document.location.href = data.url;
                    } }/>
                  {/* </a> */}                  
                </div>
              </ListItem>
            );
          })}

        </div>    
    )
}

export default Menufav234x60;