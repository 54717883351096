import * as React from 'react';

import CircularProgress, {
    circularProgressClasses,
  } from '@mui/material/CircularProgress';

  import Box from '@mui/material/Box';


function FacebookCircularProgress(props) {
    return (
      <Box sx={{ position: 'relative' }}>
        <CircularProgress
          variant="determinate"
          sx={{
            color: (theme) =>
              theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
          }}
          size={120}
          thickness={4}
          {...props}
          value={100}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          sx={{
            color: (theme) => (theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'),
            animationDuration: '550ms',
            position: 'absolute',
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: 'round',
            },
          }}
          size={120}
          thickness={4}
          {...props}
        />
      </Box>
    );
}

export default function Preloader() {
  return (
    <Box sx={{ 
        display: 'flex' , 
        justifyContent: 'center', 
        height: '100%',
        position: 'absolute',
        width: '100%',
        alignItems: 'center'
      }}
    >

     <FacebookCircularProgress />

    </Box>
  );
}
