import React from 'react';
import s from './ItemChoice.module.css';

import Paper from '@mui/material/Paper';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';

const ItemChoice = (props) => {
    // перенесено на уровень выше 
    // const [ chosen, setСhosen ] = React.useState(0);

    return (
        <>
        { (Object.keys(props.ItemData).includes('choice')) ?
        <Paper elevation={0} className = {s.mainChoiceContainer}>
          {/* <Stack spacing={1} direction="row"> */}
          <ButtonGroup variant="outlined" aria-label="outlined button group" fullWidth = {true} orientation={'horizontal'}>
          {props.ItemData.choice.map((ch, index) => {
            return(
            index === props.chosen?
            <Button key = {index} variant="contained" sx = {{flexGrow:1}} onClick = {() => props.setСhosen(index)}>{ch.size} см.</Button>
            :
            <Button key = {index} sx = {{flexGrow:1}} onClick = {() => props.setСhosen(index)} >{ch.size} см.</Button>              
            )
          })}
          </ButtonGroup>
          {/* </Stack> */}
        </Paper>
        : null }
        </>
    )

}

export default ItemChoice;