// https://habr.com/ru/post/498860/
// https://redux.js.org/tutorials/quick-start
// https://monsterlessons.com/project/categories/redux

import {applyMiddleware, combineReducers, createStore} from "redux";
// import authReducer from "./auth-reducer";
import thunkMiddleware from "redux-thunk";
// import { reducer as formReducer } from  'redux-form'
import appReducer from "./app-reducer";
import configReducer from "./config-reducer";

let reducers = combineReducers({
//     auth: authReducer,
//     form: formReducer,
    app: appReducer,
    config: configReducer
});

let store = createStore(reducers, applyMiddleware(thunkMiddleware));
// let store = createStore(reducers);

store.subscribe(()=>{
    localStorage['stor-app'] = JSON.stringify(store.getState().app);
    localStorage['stor-cfg'] = JSON.stringify(store.getState().config);
    // console.log(store.getState());
})

window.store = store;
export default store;
