import React from 'react';
import Pagelist from "./Pagelist";
import {connect} from "react-redux";
// import {logout} from "../../redux/auth-reducer";
// import {incItemInCart} from "../../redux/app-reducer";
// import {decItemInCart} from "../../redux/app-reducer";
import { updateLastSearch } from "../../redux/app-reducer";
// import { clearLastInsertItem, updateRectLastInsertItem} from "../../redux/app-reducer";

class PagelistContainer extends React.Component {

    componentDidMount() {
    //     this.props.getAuthUserData();
    // посмотреть State
        // console.log(this.props);
    }

    render() {
        return <Pagelist {...this.props} />
    }
}

const mapStateToProps = (state) => ({
    stateCfg: state.config,
    // lastinsertitem: state.app.lastinsertitem,
    // rect: state.app.rect,
});

const mapDispatchToProps = (dispatch) => {
    return {
        // laddCart: (addCartItem) => {
        //     dispatch(addItemInCart(addCartItem));
        // },
        // lclear: () => {
        //     dispatch(clearLastInsertItem());
        // },
        // updaterect: (rect) => {
        //     dispatch(updateRectLastInsertItem(rect))
        // }
        updateItems: (Item) => {
            dispatch(updateLastSearch(Item));
        }          
    }
}

// export default connect(mapStateToProps) (PagecartContainer);
export default connect(mapStateToProps, mapDispatchToProps) (PagelistContainer);