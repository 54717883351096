// страница поиска
import React from 'react';
import s from './Pagesearch.module.css';
import Grow from '@mui/material/Grow';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useNavigate } from "react-router-dom";
import { delayForAnimation } from '../../components/common/utils';

import { Box, TextField } from '@mui/material';
// import SearchIcon from '@mui/icons-material/Search';
// import Menuchip from 'components/Menuchip/Menuchip';
import ListitemsContainer from 'components/Listitems/ListitemsContainer';

import {getData} from 'api/api';

const Pagesearch = (props) => {

    const navigate = useNavigate();

    // let items = getData.find();
    
    let chipData = getData.chip();
    
    const [searchStr, setSearchStr] = React.useState("");

    // const [items, setItems] = React.useState([]);
    // const handleSubmit = (e: React.FormEvent) => {
    const handleSubmit = (e) => {
        e.preventDefault();
        let itemsl = getData.find(searchStr);
        // console.log(searchStr);
        // console.log(itemsl);
        // console.log(chipData);
        // console.log(itemsl.length);
        // setItems(itemsl);
        updateItems(itemsl);
    };

    // console.log(chipData);
    // console.log(items);

    const { updateItems } = props;
    const { items } = props;

    return (
        <Grow
        in={true}
        style={{ transformOrigin: '0 0 0' }}
        timeout = { delayForAnimation }
        >        
            <div className = {s.container}>
                <div>
                <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        sx={{ mr: 2 }}
                        onClick={()=>{
                            navigate(-1);
                        }}
                    >
                    <ArrowBackIosNewIcon />
                </IconButton>
                    <Box sx={{ display: "flex", marginRight:"10px", width:"100%"}}>
                    <form onSubmit={handleSubmit}>
                    <TextField 
                        id="searchString" 
                        placeholder="Search…"
                        variant="outlined" 
                        fullWidth = {true}
                        autoFocus = {true}                        
                        onChange = {(e)=>{setSearchStr(e.target.value)}}
                        />
                    </form>
                    </Box>
                </div>
                <div>
                    {/* {searchStr}          */}
                    {/* {console.log(items)} */}
                    {(items.length > 0)?
                    <>
                    {/* <Menuchip chipData = {chipData}  {...props}/> */}
                    {/* <ListitemsContainer itemsData = {items} chipData = {chipData} {...props}/> */}
                    <ListitemsContainer itemsData = {items} chipData = {chipData} />
                    </>
                    :
                    null
                    }
                </div>
                <div className={s.bottom}></div>
            </div>
        </Grow>
    )
}

export default Pagesearch;
