import React from 'react';
import s from './Pageprofile.module.css';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FormControl } from '@mui/material';
import { RadioGroup } from '@mui/material';
import { Radio } from '@mui/material';
import { FormLabel } from '@mui/material';
import { Typography, Button, TextField } from '@mui/material';
// import { styled } from '@mui/material/styles';
import MaterialUISwitch from '../../components/common/MaterialUISwitch';
import { delayForAnimation } from '../../components/common/utils';

import { confirmationCode, sendSmsToPhoneNumber, logOut, onAuthStateCh } from "../../components/common/firebase";

const Pageprofile = (props) => {
    
    // const [checked, setChecked] = React.useState(true);
    // let v = (props.state.themePalette === 'dark')? true : false;
    const [checked, setChecked] = React.useState((props.state.themePalette === 'dark')? true : false);
    // const [magOption, setMagOption] = React.useState('vface')
    const [magOption, setMagOption] = React.useState(props.state.magOption);
    const [smxaTable, setsmxaTable] = React.useState(props.state.maxTable);

    const [phone, setPhone] = React.useState("");
    const [code, setCode] = React.useState("");
    const [showCodeButton, setShowCodeButton] = React.useState(false);


    const { updateCFG } = props;

    const handleUpdate = (item, start) => {
      // обновить store с конфигурацией
      updateCFG(item);
      // прегрузить всю страницу
      if (start) {
        window.location.reload();
      }
    }

    const handleChangeMagOption = (event) => {
        setMagOption(event.target.value);
        // переключение режима работы приложения
        handleUpdate({"checked": checked, "magOption":event.target.value, "maxTable":smxaTable}, false);
    };

    const handleChangeTheme = (event) => {
        setChecked(event.target.checked);
        // переключение темы и обнволение приложения
        handleUpdate({"checked": event.target.checked, "magOption":magOption, "maxTable":smxaTable}, true);
    };

    const handleCahgeMaxTable = (event) => {
      setsmxaTable(event.target.value);
      // установка максимального количетсва столов
      handleUpdate({"checked": checked, "magOption":magOption, "maxTable":event.target.value}, false);
      // console.log(smxaTable);
    }
    
    const { updateUser } = props;
    const { user } = props;

    // управления конектом пользователя
    // установить слушателя
    // слушаем изменение авторизации 
    // React.useMemo(() => {
        // onAuthStateCh( (a) => {
        //     console.log(a);
        //     updateUser(a);
        // });    
    // }, [ ]);

    React.useEffect(()=> {
    // updateUser({...user, signed:0});
      //   uid: user.uid,
      //   displayName: user.displayName,
      //   phoneNumber: user.phoneNumber,
      //   email:  user.email,
      //   signed: 1,
      //   возможно проверять налицие обработчика window.myAuth.onAuthStateChanged
      //   если его нет, то навешивать !!! onAuthStateChanged
      //   Object.keys(ItemData).includes('extensions')
      // Object.keys(window.myAuth)
        // if (window.hasOwnProperty('myAuth') && !window.myAuth.hasOwnProperty('onAuthStateChanged')) {
        if (window.hasOwnProperty('myAuth')) { 
        // console.log(window.hasOwnProperty('myAuth'));
            // console.log(window.hasOwnProperty('myAuth'));
            // console.log(window.myAuth.hasOwnProperty('onAuthStateChanged'));
            // console.log("may be ... registring event for onAuthStateCh");
            onAuthStateCh( (a) => {
                // console.log(a);
                updateUser(a);
            });
        }
        // console.log("effects --- user");
    }, [ updateUser ]);
    
    return (
        <Grow
        in={true}
        style={{ transformOrigin: '0 0 0' }}
        // timeout = {500}
        timeout = { delayForAnimation }
        >        
            <div className = {s.container}>
                <Paper elevation={0} className = {s.pageprofile}>
                    <h3>Profile</h3>
                </Paper>
                
                <Paper elevation={1}>
                <FormControlLabel
                    control={<MaterialUISwitch sx={{ m: 1 }} checked={checked} onChange={handleChangeTheme}/>}
                label="Переключить тему"
                />
                </Paper>

                <Paper elevation={1}>
                <FormControl>
                    <FormLabel id="controlled-radio-buttons-group">режим работы</FormLabel>
                    <RadioGroup
                        aria-labelledby="controlled-radio-buttons-group"
                        name="name-controlled-radio-buttons-group"
                        value={magOption}
                        onChange={handleChangeMagOption}
                    >
                        <FormControlLabel value="vface" control={<Radio />} label="Магазин" />
                        <FormControlLabel value="el-menu" control={<Radio />} label="Электронное меню" />
                        <FormControlLabel value="stuf" control={<Radio />} label="Сотрудник в зале" />
                    </RadioGroup>
                </FormControl>
                </Paper>
                {/* настройка максимального количества столов */}
                { (magOption === 'stuf') ?
                <Grow in={true} style={{ transformOrigin: '0 0 0' }} timeout = {delayForAnimation}>
                <Paper elevation={1} sx={{padding: '15px'}}>
                  {/* props.state.maxTable */}
                  <TextField id="field-max-table" label="Всего столов" variant="outlined" type="number" fullWidth = {true} 
                  defaultValue = {props.state.maxTable}
                  onChange = {handleCahgeMaxTable}/>
                </Paper>
                </Grow>
                : null
                }
                {/* size="large" */}
                <Paper elevation={1} className = {s.personalActions}>
                  <Typography variant="body2" gutterBottom>
                  <Button href="https://vk.com/topic-203396728_48334239">Акции и купоны</Button>
                  </Typography>
                </Paper>
                {/* for login from SMS and recived CODE */}
                <Paper elevation={0} className = {s.loginOTPForm} >
                {( user.signed !== 1) ?
                    <>
                    <TextField 
                        id="phone" 
                        label="телефон" 
                        variant="outlined" 
                        fullWidth={true}
                        type="tel"
                        onChange = {(e)=>{setPhone(e.target.value)}}
                        />
                    <Button 
                        variant="outlined" 
                        fullWidth={true} 
                        size="large"
                        onClick={ () => {
                            console.log("|"+phone+"|");
                            let a = sendSmsToPhoneNumber(phone);
                            console.log(a);
                            setShowCodeButton(true);
                        }}
                        >Получить CODE
                    </Button>
                    {/* сделать пояснение после отправки кода */}
                    <TextField 
                        id="code" 
                        label="код" 
                        variant="outlined" 
                        fullWidth={true}
                        type="number"
                        disabled = {!showCodeButton}
                        onChange = {(e)=>{setCode(e.target.value)}}
                        />
                    <Button 
                        variant="outlined" 
                        fullWidth={true} 
                        size="large"
                        disabled = {!showCodeButton}
                        onClick={ () => {
                            console.log(code);
                            confirmationCode(code);
                        }}
                        >Подтвердить CODE
                    </Button>
                    {/* отдельное появление - после отпарвки кода */}
                    </>
                    : 
                    <Button 
                        variant="outlined" 
                        fullWidth={true} 
                        size="large"
                        onClick={ () => {
                            console.log("logOut");
                            logOut();
                        }}
                        >Выйти
                    </Button>
                    }
                </Paper>
            </div>
            
        </Grow>
    )
}

export default Pageprofile;
