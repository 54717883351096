import React from 'react';
import Pageprofile from "./Pageprofile";
import {connect} from "react-redux";
// import {logout} from "../../redux/auth-reducer";
// import {addItemInCart, updateItemInCart} from "../../redux/app-reducer";
import { updateCfgApp, updateCfgUser } from "../../redux/config-reducer";
// import { getData } from 'api/api';
// import { useParams } from "react-router";


const PageprofileContainer = (props) => {
    
    // console.log(props); 
    
    return (
        <Pageprofile {...props} />
    )
}

let mapStateToProps = (state) => {
    return {
      state: state.config,
      user: state.config.user
    }
  }
  
let mapDispatchToProps = (dispatch) => {
    return {
        updateCFG: (Item) => {
            dispatch(updateCfgApp(Item));
        },
        updateUser: (Item) => {
            dispatch(updateCfgUser(Item));
        }        
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (PageprofileContainer);
